// Material UI
import { Box, Typography, makeStyles, Grid, Button } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

// Libraries
import moment from 'moment';
import { useTranslation } from "react-i18next";
// Assets
import drv from '../../assets/Images/drv/logo-drv-blanco.png'; // siempre por defecto
// Custom Style
const EventStyles = makeStyles(theme => ({
    backgroundCustom: {
        borderRadius: '8px 8px 8px 8px',
        marginBottom: '20px',
        marginTop: '30px',
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: 'center !important',
        backgroundSize: 'cover !important'
    },
    backgroundDesign: {
        borderRadius: '8px 8px 8px 8px',
        maxWidth: '100%',
        // marginBottom:'20px',
        marginTop: '15px',
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: 'center !important',
        backgroundSize: 'cover !important'
    },
    title: {
        fontSize: '18px',
        padding: '15px 0px'
    },
    description: {
        fontSize: '15px',
    },
    logo: {
        height: '40px',
        width: 'auto',
        margin: '0px 30px',
        maxWidth: '240px',
        objectFit: 'scale-down'
    },
    actionBtn: {
        borderRadius: theme.spacing(1),
        padding: `10px ${theme.spacing(2)}px`,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.dark,
        }
    },
    content: {
        marginTop: 32,
    }
}));

// Types
type EventTemplateProps = {
    event: any
}


export default function EventTemplate({ event }: EventTemplateProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    // Styles
    const classes = EventStyles();
    /* Global Variables */
    const types = [
        t("admin.events.webinar"),
        t("admin.events.instagram-live"),
        t("admin.events.live"),
        t("admin.events.training"),
        t("admin.events.fair"),
        t("admin.events.question-session"),
        t("admin.events.launch"),
        t("admin.events.ftf-event")
    ];

    return (
        <>
            {(event.method === 0) ?
                <Box
                    className={classes.backgroundCustom}
                    style={{ background: (event.image !== null) ? `url(${encodeURI(event.image)})` : `${encodeURI(`url(${process.env.REACT_APP_AWS_URL}/brands/${event.brand.background})`)}`, color: '#ffffff' }} >
                    <Box alignContent="center" pt={2} pb={2}>
                        <Typography
                            align="center"
                            className={classes.description}
                            style={{ color: (event.text_color != null) ? event.text_color : '#000' }}>
                            {t('you-are-invated-to')} {types[event.type]}:
                        </Typography>
                    </Box>

                    <Box
                        pt={2} pb={2} mb={4}
                        style={{ background: fade((event.background_color != null) ? event.background_color : '#ffffff', 0.3) }}>
                        <Typography
                            align="center"
                            className={classes.title}
                            style={{ color: (event.text_color != null) ? event.text_color : '#000' }}>
                            {event.title}
                        </Typography>
                    </Box>

                    <Box pt={3} pb={3}>
                        <Typography
                            align="center"
                            className={classes.description}
                            style={{ color: (event.text_color != null) ? event.text_color : '#000' }}>
                            {event.description}
                        </Typography>
                    </Box>

                    <Box pt={4} pb={1}>
                        <Typography
                            align="center"
                            className={classes.description}
                            style={{ color: (event.text_color != null) ? event.text_color : '#000' }}>
                            {`${moment(event.date_ini).format('dddd DD \\d\\e MMMM \\d\\e YYYY')}`}
                        </Typography>
                        {(event.date_end != null) ?
                            <Typography
                                align="center"
                                className={classes.description}
                                style={{ color: (event.text_color != null) ? event.text_color : '#000' }}>
                                {`${moment(event.date_ini).format('\\H\\o\\r\\a: HH:mm A')} a ${moment(event.date_end).format('HH:mm A')}`}
                            </Typography>
                            :
                            <Typography
                                align="center"
                                className={classes.description}
                                style={{ color: (event.text_color != null) ? event.text_color : '#000' }}>
                                {`${moment(event.date_ini).format('\\H\\o\\r\\a: HH:mm A')}`}
                            </Typography>
                        }

                    </Box>

                    {(event.speaker != null) &&
                        <Box pt={1} pb={4}>
                            <Typography
                                align="center"
                                className={classes.description}
                                style={{ color: (event.text_color != null) ? event.text_color : '#000' }}>
                                {t('speaker')}: <br /> {event.speaker}
                            </Typography>
                        </Box>
                    }
                    {event.link &&
                        <Box pt={4} pb={4} style={{ textAlign: 'center' }}>
                            <Button
                                color="primary"
                                variant="outlined"
                                component="a"
                                target="_blank"
                                href={event.link}
                                style={{ color: (event.text_color != null) ? event.text_color : '#000', borderColor: (event.text_color != null) ? event.text_color : '#000' }}>
                                {(event.text_button != null) ? event.text_button : t("see-more")}
                            </Button>
                            {/* <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => {history.push(`/eventos/${event.id}`)}}
                                style={{ color: (event.text_color != null) ? event.text_color : '#000', borderColor: (event.text_color != null) ? event.text_color : '#000' }}>
                                {t("see-details")}
                            </Button> */}
                        </Box>
                    }

                    <Grid container justify="space-between" alignItems="center" spacing={3}>
                        <Grid item>
                            <img src={drv} className={classes.logo} />
                        </Grid>

                        <Grid item>
                            {event.brand != null &&
                                <img src={process.env.REACT_APP_AWS_URL + '/brands/' + event.brand.dark_logo} alt={`${event.brand.name} Logo`} className={classes.logo} />
                            }
                        </Grid>

                    </Grid>

                </Box>
                :
                <img src={`${event.image}`} alt={event.title} className={classes.backgroundDesign} />
            }
        </>
    );
}