// React
import { useState, MouseEvent, memo } from "react";
import { useSelector } from 'react-redux';
import moment from 'moment';

// Material UI
import { Typography, Grid, Box, Chip, IconButton, Menu, MenuItem, Button, Card, CardContent, CardActions } from '@material-ui/core';
import { Description, Videocam, MoreVert, BusinessRounded } from '@material-ui/icons';

//Custom components
import CustomChip from '../admin/CustomChip'
import ShareIcon from '../ShareIcon'
import Visibility from '../client/Visibility';
import FiletypeIcon from '../../../components/library/FiletypeIcon';
import TooltipCards from "../typography/TooltipCards";
import HiddenMobile from "../HiddenMobile";
import HiddenDesktop from "../HiddenDesktop";
import FavoriteButton from "../FavoriteButton";

// Redux Actions
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from "../../../hooks/useScreenWidth";

// Styles
import VipStyles from '../../../styles/components/common/VipStyles';
import BlockStyles from '../../../styles/components/library/ResourceStyle';

type MediaFileBlockProps = {
    data: any,
    vipContent: boolean,
    hasaccess: boolean,
    handleClick: (id: number, hasaccess: boolean) => void,
    handleDownload: (resource_id: number) => void,
    handleFavorite: (state: boolean) => void,
    brand: string,
    isCatalog?: boolean
}

function MediaFileBlock({ data, vipContent, hasaccess = false, handleClick, handleDownload, handleFavorite, brand, isCatalog = false }: MediaFileBlockProps) {

    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    // Styles
    const classes = BlockStyles();
    const vipclasses = VipStyles();

    //Redux Hooks
    const [anchorOp, setAnchorOp] = useState<null | HTMLElement>(null);
    const [openOptions, setOpenOptions] = useState(false);
    const { user: authUser } = useSelector((state: IRootState) => state.auth);

    /* Functions */
    const handleCloseOptions = () => {
        setAnchorOp(null);
        setOpenOptions(false);
    };

    const TypeFile = () => {
        const fileNameExtension = data.ext === null ? '' : data.ext.toUpperCase();

        const $imageExtensions = ['JPG', 'GIF', 'PNG', 'JPEG', 'BMP', 'TIF'];
        const $fileExtensions = ['PDF', 'DOCX', 'XLS', 'XLSX', 'CSV'];
        const $videoExtensions = ['MP4', 'MOV', 'WMV', 'AVI'];

        if ($imageExtensions.includes(fileNameExtension) || data.cover !== null) {
            const tmpFilename = (data.cover === null || data.cover === undefined) ? data.file_name : data.cover;
            return <Box className={classes.media}
                style={{
                    background: `${encodeURI(`url(${process.env.REACT_APP_AWS_URL}/resources/${tmpFilename})`)}`
                }}
                title={data.file_name} >
                {(vipContent) &&
                    <Box textAlign="center" className={vipclasses.label}>
                        <Typography variant={isMobile ? 'body1' : "subtitle1"}>VIP</Typography>
                    </Box>
                }
            </Box>
        } else if ($videoExtensions.includes(fileNameExtension)) {
            return <Box className={classes.media}>
                <Videocam fontSize="large" color="inherit" />
                {(vipContent) &&
                    <Box textAlign="center" className={vipclasses.label}>
                        <Typography variant={isMobile ? 'body1' : "subtitle1"}>VIP</Typography>
                    </Box>
                }
            </Box>
        } else if ($fileExtensions.includes(fileNameExtension)) {
            return <Box className={classes.media}>
                <Description fontSize="large" color="inherit" />
                {(vipContent) &&
                    <Box textAlign="center" className={vipclasses.label}>
                        <Typography variant={isMobile ? 'body1' : "subtitle1"}>VIP</Typography>
                    </Box>
                }
            </Box>
        } else {
            return <Box className={classes.media}>
                <Description fontSize="large" color="inherit" />
                {(vipContent) &&
                    <Box textAlign="center" className={vipclasses.label}>
                        <Typography variant={isMobile ? 'body1' : "subtitle1"}>VIP</Typography>
                    </Box>
                }
            </Box>
        }
    }

    return (
        <>
            <Grid item xs={6} sm={4} md={useScreenWidth(1000) ? 6 : 4} lg={(isCatalog) ? 4 : 3} className='websiteResource'>

                {/* <CustomChip type={2} label="NUEVO" custom="new" />  */}

                <Card className={isCatalog ? classes.catalogCard : classes.card + ' ' + (vipContent && vipclasses.shadow)} >
                    <Button fullWidth onClick={() => handleClick(data.id, !(vipContent && !hasaccess))} style={{ padding: 0 }}>
                        <TypeFile />
                    </Button>

                    <CardContent classes={{ root: classes.resourceText1 }}>
                        <Box
                            onClick={() => handleClick(data.id, !(vipContent && !hasaccess))}
                            className={classes.title}>
                            <HiddenDesktop>
                                <FiletypeIcon id={data.filetype_id} />
                            </HiddenDesktop>
                            <TooltipCards titleCard={data.alias && data.alias !== null ? data.alias : data.file_name} classNameCustom='resourceTitle' />
                            {isCatalog ?
                                <HiddenMobile>
                                    <Typography variant="caption" color="textSecondary">
                                        {`${moment(data.date === null ? data.created_at : data.date).format(isMobile ? 'l' : 'LL')}`}
                                    </Typography>
                                </HiddenMobile>
                                : (vipContent) ?
                                    <HiddenMobile>
                                        <Chip label={data.filetype} className={vipclasses.chip} />
                                    </HiddenMobile>
                                    :
                                    <HiddenMobile>
                                        <CustomChip type={2} label={data.filetype} />
                                    </HiddenMobile>
                            }
                        </Box>
                    </CardContent>
                    <CardActions
                        style={isMobile
                            ? { padding: '0px 6px 0px 0px', justifyContent: 'space-between' }
                            : { padding: '0px 12px', justifyContent: 'space-between' }}
                        disableSpacing>
                        <Box display="flex" pl={isMobile && '6px'}>
                            {handleFavorite &&
                                <FavoriteButton handleClick={handleFavorite} favoriteState={Boolean(data.favoriteId)} />
                            }
                            <IconButton
                                id={`icon-button-${data.id}`}
                                className={`card-options ${classes.optionsMobile}`}
                                aria-controls="icon-menu"
                                aria-haspopup="false"
                                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                                    setAnchorOp(event.currentTarget);
                                    setOpenOptions(true);
                                }}>

                                <MoreVert />
                            </IconButton>
                        </Box>
                        <Box
                            display='flex' flexDirection='column' alignItems='flex-end' gridGap={2}
                            onClick={() => handleClick(data.id, !(vipContent && !hasaccess))}
                            pr={1}>
                            {isCatalog ?
                                <HiddenDesktop>
                                    <Typography variant="caption" color="textSecondary">
                                        {`${moment(data.date === null ? data.created_at : data.date).format(isMobile ? 'l' : 'LL')}`}
                                    </Typography>
                                </HiddenDesktop> :
                                <Visibility variant={isMobile && 5} type={data.visibility} />
                            }
                            {(brand !== undefined && brand !== '') &&
                                <>
                                    <HiddenMobile>
                                        <Box display="flex" gridGap={4}>
                                            <BusinessRounded fontSize="small" htmlColor="#8F99A3" />
                                            <Typography variant="body2" color="textSecondary">{brand}</Typography>
                                        </Box>
                                    </HiddenMobile>
                                    <HiddenDesktop>
                                        <Typography variant="caption" color="textSecondary">{brand}</Typography>
                                    </HiddenDesktop>
                                </>
                            }
                        </Box>
                    </CardActions>
                </Card>
                {/* Hidden content vip */}
                {vipContent && !hasaccess &&
                    <Box alignContent="center" textAlign="center" className={vipclasses.block}>
                        <Typography variant="subtitle1" color="inherit">{t('this-is-a-content')}</Typography>
                        <Typography variant="h5" color="inherit">VIP</Typography>
                    </Box>
                }
            </Grid>

            <Menu
                id={`simple-menu-${data.id}`}
                anchorEl={anchorOp}
                getContentAnchorEl={null}
                keepMounted
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                open={openOptions}
                onClose={handleCloseOptions}>
                {handleClick &&
                    <MenuItem onClick={() => handleClick(data.id, !(vipContent && !hasaccess))}>{t("view")}</MenuItem>
                }
                <MenuItem onClick={() => handleDownload(data.id)}>{t("download")}</MenuItem>

                <ShareIcon
                    component='menuItem'
                    visibility={data.visibility}
                    title={data.alias !== null ? data.alias : data.file_name}
                    description={data.alias}
                    type="resource"
                    link={`${window.location.origin}${window.location.pathname === '/comercial' ? '/biblioteca' : window.location.pathname}/${data.id}${window.location.search}`}
                    multimedia={`${process.env.REACT_APP_AWS_URL}/resources/${data.file_name}`}
                    multimediaType={data.ext === 'mp4' ? 'video' : 'image'}
                    foreignId={data.id}
                />
            </Menu>

        </>
    );
}

export default memo(MediaFileBlock);
