import { 
    START_STORE_USERSETTINGS,
    START_KTB_VIDEO_USERSETTINGS,
    START_FIRST_START_VIDEO_USERSETTINGS
} from '../types';

export const storeUsersettings = payload => ({
    type: START_STORE_USERSETTINGS,
    payload
})

export const ktbVideoUsersettings = () => ({
    type: START_KTB_VIDEO_USERSETTINGS
})

export const firstStartVideoUsersettings = () => ({
    type: START_FIRST_START_VIDEO_USERSETTINGS
})