// React
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material UI
import { Button, Backdrop, Modal, Fade, Box } from "@material-ui/core";
import { ChevronRightRounded } from "@material-ui/icons";

// Custom Components
import ContentHeroStyle from "../../styles/components/HomeStyles";

// Custom Hooks
import useBooleanState from "../../hooks/useBooleanState";

// Redux Actions
import { IRootState } from "../../redux/types";
import {
  storeUsersettings,
  firstStartVideoUsersettings,
} from "../../redux/actions/usersettings";

// Translate
import { useTranslation } from "react-i18next";

function ShowFirstStartVideo() {
  // Translate
  const { t } = useTranslation(["global"]);

  // Styles
  const classes = ContentHeroStyle();

  //Redux Hooks
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((state: IRootState) => state.auth);
  const { usersettings } = useSelector(
    (state: IRootState) => state.usersetting
  );

  const languageCode = localStorage.getItem('globalLanguageCode') !== null ? localStorage.getItem('globalLanguageCode') : 'es';

  useEffect(() => {
    if (authUser.settings !== null) {
      toggleOpen(authUser.settings.show_first_start_video === 0 ? true : false);
    } else {
      dispatch(storeUsersettings({ display_resources: 1 }));
    }
  }, []);

  useEffect(() => {
    if (
      usersettings !== null &&
      usersettings !== undefined &&
      usersettings.show_first_start_video !== undefined
    ) {
      toggleOpen(usersettings.show_first_start_video === 0 ? true : false);
    }
  }, [usersettings]);

  /* Functions */
  const [open, toggleOpen] = useBooleanState(false);

  const handleCloseVideo = () => {
    dispatch(firstStartVideoUsersettings());
    toggleOpen(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalVideo}
      open={open}
      onClose={handleCloseVideo}
      disableRestoreFocus
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className={classes.containerVideo} p={4}>
          <Box className={classes.videoContent}>
            <iframe
              src={
                languageCode === 'es' ?
                  "https://player.vimeo.com/video/668629089?h=a9c38ae5b1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  :
                  "https://player.vimeo.com/video/913345132?h=a9c38ae5b1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              }
              allow="autoplay; fullscreen; picture-in-picture"
              title={`Bienvenida a ${process.env.REACT_APP_NAME} Horizontal.mp4`}
              allowFullScreen
            ></iframe>
          </Box>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={2}
          >
            <Button
              size="small"
              onClick={handleCloseVideo}
              variant="contained"
              color="primary"
            >
              {t("continue")}
              <ChevronRightRounded />
            </Button>
          </Box>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ShowFirstStartVideo;
