// React
import { useState, ChangeEvent, useEffect } from 'react'
import { useSelector } from 'react-redux'

// Material UI
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    Typography,
    Checkbox,
    TextField,
    InputBase,
    DialogActions,
    Button,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { CheckBoxOutlineBlank, CloseRounded, Search } from '@material-ui/icons'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

// Custom components
import { SaveButton } from '../../forms'

// Redux Actions
import { IRootState } from '../../../../redux/types'

// Styles
import FormsStyles from '../../../../styles/components/common/forms/FormsStyles'

// Libraries
import { useTranslation } from 'react-i18next'

//Custom Hook
import useScreenWidth from '../../../../hooks/useScreenWidth'

// Global Constants
const icon = <CheckBoxOutlineBlank fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

// Types
interface ICountry {
    id: number
    code: string
    name: string
}

type FormElement = ChangeEvent<HTMLInputElement>

function InputCountry({
    value: countriesSelected,
    handleInputChange,
    userSelected = [],
    label = null,
    required = false
}: any) {
    // Styles
    const formClasses = FormsStyles()

    // Translate
    const { t } = useTranslation(['global'])

    //Custom Hook
    const isMobile = useScreenWidth(700);

    // Redux Hooks
    const { user: authUser } = useSelector((state: IRootState) => state.auth)
    const { countries } = useSelector((state: IRootState) => state.country)
    const { errorForm, msgForm } = useSelector(
        (state: IRootState) => state.validator
    )

    // Handle modal
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setCountriesFiltered(countries)
    }, [countries])

    // Handle filter results
    const [countriesFiltered, setCountriesFiltered] =
        useState<ICountry[]>(countries)

    const handleCheckAllChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { checked } = event.target
        let allCountriesIds: number[] = []
        if (checked) allCountriesIds = authUser.countries

        handleInputChange('countries', allCountriesIds)
    }

    const handleMultiAutoCompleteChange = () => (event: any, value: any) => {
        let optionsMulti: number[] = []
        if (value !== null) {
            for (let i = 0; i < value.length; i++) {
                optionsMulti.push(value[i].id)
            }
        }

        handleInputChange('countries', optionsMulti)
    }

    const handleCheckChange = (event: FormElement) => {
        let { value, checked } = event.target
        const checkValue = parseInt(value)
        let countries = [...countriesSelected]

        // Add or delete country
        if (checked) countries.push(checkValue)
        else
            countries = countries.filter(
                (country_id: number) => country_id !== checkValue
            )

        handleInputChange('countries', countries)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const onOpenAutocomplete = (event: object) => {
        setOpen(true)
    }

    const handleSearch = (event: any) => {
        let countriesFiltered: ICountry[] = [...countries]
        const value = event.target.value

        // Search
        countriesFiltered = countriesFiltered.filter((country: ICountry) =>
            country.name.toLowerCase().includes(value.toLowerCase())
        )

        // Set results
        setCountriesFiltered(countriesFiltered)
    }

    return (
        <>
            {authUser !== null && (
                <Autocomplete
                    id="multi-autocomplete-countries"
                    data-testid="form-autocomplete-country"
                    options={countries}
                    value={countries.filter((country: any) =>
                        countriesSelected.includes(country.id)
                    )}
                    onChange={() => handleMultiAutoCompleteChange()}
                    disableCloseOnSelect
                    fullWidth
                    multiple
                    limitTags={0}
                    onOpen={onOpenAutocomplete}
                    disabled={
                        userSelected !== null
                            ? [3, 4, 6].includes(authUser.role_id) &&
                            authUser.id === userSelected.id
                            : false
                    }
                    getOptionLabel={(country: ICountry) =>
                        country.id !== undefined ? country.name : ''
                    }
                    style={{ maxHeight: 92, overflowY: 'auto' }}
                    renderOption={(country: ICountry, { selected }: any) => (
                        <>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 4 }}
                                onChange={handleCheckChange}
                                checked={countriesSelected.includes(country.id)}
                                value={country.id}
                            />
                            {country.name}
                        </>
                    )}
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            error={errorForm.countries}
                            helperText={msgForm.countries}
                            variant="filled"
                            label={label === null ? t('countries') : label}
                            InputLabelProps={{ shrink: true }}
                            placeholder={t('selected-countries')}
                            required={required}
                        />
                    )}
                />
            )}

            <Dialog
                id="dialog-countries-container"
                open={open}
                onClose={handleClose}
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "md"}
                className={formClasses.containerForm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="dialog-countries-title">
                    <Grid container justify="space-between">
                        <Typography variant="h6">
                            {t('selected-countries')} | {countriesSelected.length}
                        </Typography>
                        <Button onClick={handleClose} color='primary' variant="text"><CloseRounded /></Button>
                    </Grid>
                </DialogTitle>

                <DialogContent className={formClasses.bodyForm}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormGroup>
                                <Box
                                    mb={3}
                                    px={4}
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                data-inputcountry="selectAll"
                                                id="chk-all-countries"
                                                onChange={handleCheckAllChange}
                                            />
                                        }
                                        label={t('select-all')}
                                        className={formClasses.inputSelectAll}
                                    />
                                    <div className={formClasses.search}>
                                        <div className={formClasses.searchIcon}>
                                            <Search />
                                        </div>
                                        <InputBase
                                            data-inputcountry="searchCountry"
                                            placeholder={t('countries') + '...'}
                                            classes={{
                                                root: formClasses.inputRoot,
                                                input: formClasses.inputInput,
                                            }}
                                            inputProps={{
                                                'aria-label': t('country'),
                                            }}
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </Box>
                                <Grid
                                    container
                                    justify="space-between"
                                    alignItems="center"
                                    className={formClasses.listCountries}
                                >
                                    {countriesFiltered.map(
                                        (country: ICountry, index: number) => (
                                            <Grid key={country.id} item xs={6} md={4}>
                                                <FormControlLabel
                                                    key={index}
                                                    control={
                                                        <Checkbox
                                                            data-inputcountry={
                                                                country.name
                                                            }
                                                            checked={countriesSelected.includes(
                                                                country.id
                                                            )}
                                                            onChange={
                                                                handleCheckChange
                                                            }
                                                            value={country.id}
                                                        />
                                                    }
                                                    label={country.name}
                                                />
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            </FormGroup>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SaveButton
                        autoFocus
                        id="btn-countries-continue"
                        onClick={handleClose}
                    >
                        {t('continue')}
                    </SaveButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default InputCountry
