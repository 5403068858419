// React
import { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Button, DialogActions, Dialog, DialogTitle, DialogContent,
    Grid, TextField, Typography, FormHelperText, Tabs, Tab, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox,
    Radio, RadioGroup
} from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons/';

// Custom components
import { SaveButton, CancelButton } from '../common/forms';
import TabPanel from '../common/admin/TabPanel';
import TinyRichEditor from '../common/admin/TinyRichEditor';

// Redux Actions
import { createLesson, toggleFormLesson } from '../../redux/actions/lessons';
import { formValidator, resetFormValidator } from '../../redux/actions/validator';
import { IRootState } from '../../redux/types';


// Libraries
import Dropzone, { ILayoutProps } from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { useTranslation } from "react-i18next";

//Custom Hooks
import useScreenWidth from '../../hooks/useScreenWidth';

// Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles';

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// Types
interface ILessonEditor {
    id: null | number,
    fileNames: string[],
    courseId: null | number,
    title: null | string,
    content: string,
    order: null | number,
    duration: null | number,
    notifications: any
}

type FormElement = ChangeEvent<HTMLInputElement>;

type LessonEditorProps = {
    courseId: number,
    courseTitle: string,
    courseLastOrder: number
}

//Dropzone
const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) => {
    return (
        <div>
            {previews}

            <div {...dropzoneProps}>{files.length < maxFiles && input}</div>

            {files.length > 0 && submitButton}
        </div>
    )
}

function LessonEditorText({ courseId, courseTitle, courseLastOrder }: LessonEditorProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const formClasses = FormsStyles();

    //Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { lesson_selected, confirm, showForm } = useSelector((state: IRootState) => state.lesson);
    const { isValidForm, errorForm, errorTab, msgForm, msgTab } = useSelector((state: IRootState) => state.validator);
    const { globalBrandId } = useSelector((state: IRootState) => state.global);

    // Lesson State
    const [lessonState, setLessonState] = useState<ILessonEditor>({
        id: null,
        fileNames: [],
        courseId: courseId,
        title: '',
        content: '',
        order: courseLastOrder + 1,
        duration: 1,
        notifications: { email: 0, notification: 0, integration: 0, hierarchy: 0 }
    });
    const [minOrder, setMinOrder] = useState(1);

    // Ready Page
    useEffect(() => {
        if (lesson_selected != null)
            setLessonState({
                id: lesson_selected.id,
                fileNames: [],
                courseId: lesson_selected.course.id,
                title: lesson_selected.title,
                content: lesson_selected.content,
                order: lesson_selected.order,
                duration: lesson_selected.duration,
                notifications: (lesson_selected.notifications !== null) ? JSON.parse(lesson_selected.notifications) : { email: 0, notification: 0, integration: 0, hierarchy: 0 }
            });
    }, []);

    // Send Form
    useEffect(() => {
        if (isValidForm) {
            dispatch(resetFormValidator());
            dispatch(createLesson(lessonState));
        }
    }, [isValidForm]);

    /* Functions */
    const handleInputChange = (inputName: string) => (event: FormElement) => {
        const value = event.target.value;
        setLessonState({ ...lessonState, [inputName]: value });
    }

    const handleEditorChange = (e: any) => {
        setLessonState({ ...lessonState, content: e.target.getContent() });
    }

    const handleNotificationsChange = (inputName: string) => (event: FormElement) => {
        let value;
        if (inputName === 'hierarchy')
            value = event.target.value;
        else
            value = event.target.checked;

        let tmpNotificationJson = { ...lessonState.notifications };
        tmpNotificationJson[inputName] = value;
        setLessonState({ ...lessonState, notifications: tmpNotificationJson });
    }


    const handleSubmit = () => {
        dispatch(formValidator({
            title: [lessonState.title, 'required|max:50|min:2'],
            courseId: [lessonState.courseId, 'required|numeric'],
            content: [lessonState.content, 'notempty', 'general'],
            order: [lessonState.order, 'required|numeric|min:1'],
            duration: [lessonState.duration, 'required|numeric|min:1']
        }));
    }

    const handleConfirmSubmit = () => {
        dispatch(createLesson({ ...lessonState, confirmOrder: (lesson_selected?.order) ? lesson_selected.order : (courseLastOrder + 1) }));
    }

    // Tabs
    const [currentTab, setCurrentTab] = useState(0);
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    //Dropzone
    const getUploadParams = ({ meta }: any) => {
        let tmpFileNames = [...lessonState.fileNames];
        tmpFileNames.push(meta.name);
        setLessonState({ ...lessonState, fileNames: tmpFileNames });
        return { url: `${process.env.REACT_APP_API_URL}/lessons/uploaddropzone/${meta.name}` }
    }

    return (
        <>
            <Dialog
                open={showForm}
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "md"}
                className={formClasses.containerForm}
                onClose={() => dispatch(toggleFormLesson())}
                aria-labelledby="form-dialog-title"
                disableEnforceFocus>

                <DialogTitle id="form-dialog-title">
                    <Grid container justify="space-between">
                        <Typography variant="h6">
                            {lessonState.id !== null ? `${t('edit')} ${t('lesson')}` : `${t('create')} ${t('lesson')}`}
                        </Typography>
                        <Button onClick={() => dispatch(toggleFormLesson())} color="primary" variant="text"><CloseRounded /></Button>
                    </Grid>
                </DialogTitle>
                <DialogContent className={formClasses.bodyForm}>
                    <Grid container alignItems="flex-start" justify="space-between" spacing={2}>

                        <Grid item xs={6} md={5}>
                            <TextField
                                label={t("title")}
                                fullWidth
                                required
                                value={lessonState.title}
                                error={errorForm.title}
                                helperText={msgForm.title}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ 'data-createlesson': 'name' }}
                                onChange={handleInputChange('title')}
                                variant="filled" />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <TextField
                                label={t("course")}
                                fullWidth
                                disabled
                                margin="normal"
                                defaultValue={courseTitle}
                                variant="filled" />
                        </Grid>

                        <Grid item xs={6} md={2}>
                            <TextField
                                label={t("duration") + '(Min)'}
                                type="number"
                                fullWidth
                                required
                                margin="normal"
                                value={lessonState.duration}
                                error={errorForm.duration}
                                helperText={msgForm.duration}
                                onChange={handleInputChange('duration')}
                                inputProps={{ 'data-createlesson': 'duration' }}
                                variant="filled" />
                            <FormHelperText error={errorForm.duration}>{msgForm.duration}</FormHelperText>
                        </Grid>

                        <Grid item xs={6} md={2}>
                            <TextField
                                label={t("order")}
                                type="number"
                                fullWidth
                                required
                                margin="normal"
                                InputProps={{ inputProps: { min: minOrder, 'data-createlesson': 'order' } }}
                                value={lessonState.order}
                                error={errorForm.order}
                                helperText={msgForm.order}
                                onChange={handleInputChange('order')}
                                variant="filled" />
                        </Grid>

                    </Grid>
                    <Grid item xs={12}>
                        <Tabs
                            value={currentTab}
                            indicatorColor="secondary"
                            textColor="primary"
                            className={formClasses.tapForm}
                            onChange={handleChangeTab}
                            aria-label="disabled tabs example">

                            <Tab label={t("general")}  {...a11yProps(0)} className={errorTab.general ? formClasses.errorTab : ''} />
                            <Tab label={t("downloadable-material")} {...a11yProps(1)} />

                        </Tabs>
                    </Grid>

                    <TabPanel value={currentTab} index={0}>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <TinyRichEditor
                                    data-tinyrich="textarea"
                                    handleEditorChange={handleEditorChange}
                                    initialContent={lessonState.content}
                                    uploadURL='/lessons/upload'
                                />
                                <FormHelperText error={errorForm.content}>{msgForm.content}</FormHelperText>
                            </Grid>

                            <Grid item xs={12} sm={3} md={3}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{t('notifications')}</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={Boolean(lessonState.notifications.email)} onChange={handleNotificationsChange("email")} name="email" />}
                                            label={t('email')}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={Boolean(lessonState.notifications.notification)} onChange={handleNotificationsChange("notification")} name="notification" />}
                                            label={t('notification')}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={Boolean(lessonState.notifications.integration)} onChange={handleNotificationsChange("integration")} name="integration" />}
                                            label={t('integration')}
                                        />
                                    </FormGroup>
                                </FormControl>

                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Notificar a:</FormLabel>
                                    <RadioGroup aria-label="notifyTo" name="notifyTo" value={lessonState.notifications.hierarchy} onChange={handleNotificationsChange("hierarchy")}>
                                        <FormControlLabel value="0" control={<Radio />} label={t('all')} />
                                        <FormControlLabel value="1" control={<Radio />} label={t('commercial')} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                        </Grid>
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Dropzone
                                    getUploadParams={getUploadParams}
                                    LayoutComponent={Layout}
                                    classNames={{ dropzone: 'dzu-dropzone-custom', inputLabel: 'dzu-inputlabel-custom' }}
                                    inputContent={t('dropzone-drop-text')}
                                />
                            </Grid>

                        </Grid>
                    </TabPanel>
                </DialogContent>

                <DialogActions className={!isValidForm && Object.keys(msgForm).length > 0 ? formClasses.errorDialogActions : ''}>
                    <CancelButton onClick={() => dispatch(toggleFormLesson())}>
                        {lessonState.id !== null ? t("cancel") : t("cancel")}
                    </CancelButton>
                    <SaveButton data-createlesson="saveButton" onClick={() => handleSubmit()}>
                        {lessonState.id !== null ? t("save") : t("create")}
                    </SaveButton>
                </DialogActions>

            </Dialog>

            <Dialog
                open={confirm}
                fullWidth={true}
                fullScreen={isMobile && true}
                maxWidth={!isMobile && "md"}
                className={formClasses.containerForm}
                onClose={() => dispatch(toggleFormLesson())}
                aria-labelledby="form-dialog-title"
                disableEnforceFocus>

                <DialogTitle id="form-dialog-title">
                    <Grid container justify="space-between">
                        <Typography variant="h6">
                            {t("admin.courses.lesson-conflict")}
                        </Typography>
                        <Button onClick={() => dispatch(toggleFormLesson())} color="primary" variant="text"><CloseRounded /></Button>
                    </Grid>
                </DialogTitle>
                <DialogContent className={formClasses.bodyForm}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {t("admin.courses.lesson-conflict-order", { conflictOrder: (lesson_selected?.order) ? lesson_selected.order : (courseLastOrder + 1), lessonOrder: lessonState.order })}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <CancelButton onClick={() => dispatch(toggleFormLesson())} >
                        {lessonState.id !== null ? t("cancel") : t("cancel")}
                    </CancelButton>
                    <SaveButton onClick={() => handleConfirmSubmit()}>
                        {t("confirm")}
                    </SaveButton>
                </DialogActions>

            </Dialog>

        </>
    );
}

export default LessonEditorText;