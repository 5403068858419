import { useState } from 'react';

// Material UI
import { Box, Typography, Tooltip, IconButton } from "@material-ui/core";
import { BarChartRounded, Info, ListRounded, VisibilityRounded } from '@material-ui/icons';

//Custom Components
import CustomVerticalBarChart from './CustomVerticalBarChart';

// Custom Hooks
import useBestCTR from '../../hooks/graphs/useBestCTR';
import useScreenWidth from '../../hooks/useScreenWidth';

// Libraries
import { useTranslation } from "react-i18next";
import MaterialTable from 'material-table';

// Styles
import GraphsStyles from '../../styles/components/graphs/GraphsStyles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';


enum Period {
    'week',
    'month'
}

function BestCTR() {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    //Styles
    const classes = GraphsStyles();

    // Condicional para el switch de grid a lista
    const [display, setDisplay] = useState<number>(1);
    const [period, setPeriod] = useState<Period>(Period.week);

    //Toggle Button State
    const [timegap, setTimegap] = useState<string | null>('week');

    const { weekCTR, monthCTR } = useBestCTR();

    /* Functions */
    const handleTimegap = (event: React.MouseEvent<HTMLElement>, newTimegap: string | null) => {
        setTimegap(newTimegap);
    };

    const changeDisplayMode = (mode: number) => {
        setDisplay(mode)
    }

    const columns: any = [
        { title: '#', field: 'position' },
        {
            title: t("name"), field: "foreign_name", render: (rowData: any) => (
                <Tooltip placement="top" TransitionProps={{ timeout: 400 }} title={rowData.name}>
                    <Box display="flex" alignItems="center" gridGap={8} my={1} width='100%'>
                        <Box style={{ backgroundImage: `url(${rowData.image})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '32px', width: '32px', borderRadius: '4px' }} />
                        <Box display={'flex'} flexDirection={'column'} className={classes.InfoContent}>
                            <Typography variant='body2'>{rowData.foreign_name}</Typography>
                            <Typography variant='caption'>{t(rowData.menu)}</Typography>
                        </Box>
                    </Box>
                </Tooltip>
            )
        },
        { title: t("CTR"), field: "value" }
    ];

    return (
        <Box width='100%'>
            <Box width={'100%'} display={'flex'} justifyContent='space-between' alignItems={'center'} mb={2}>
                <Box display={'flex'} alignItems={'center'} gridGap={8} className={classes.heroContent}>
                    <Typography variant='subtitle1'>{t('videos-with-best-ctr')}</Typography>
                    <Tooltip placement='bottom' title={
                        <Box maxWidth={'240px'}>
                            <Typography variant='caption'>{t('videos-ctr-quote')}</Typography>
                        </Box>
                    }>
                        <Info fontSize='small' />
                    </Tooltip>
                </Box>
                <ToggleButtonGroup
                    value={timegap}
                    exclusive
                    aria-label='time gap'
                    size='small'
                    onChange={handleTimegap}
                    classes={{
                        root: classes.toogleBtnContainer
                    }}
                >
                    <ToggleButton onClick={() => setPeriod(Period.month)} value="month" aria-label='month' classes={{ root: classes.toogleBtn, selected: classes.toggleBtnSelect }}>
                        <Typography variant='body2'>{t('monthly')}</Typography>
                    </ToggleButton>
                    <ToggleButton onClick={() => setPeriod(Period.week)} value="week" aria-label='week' classes={{ root: classes.toogleBtn, selected: classes.toggleBtnSelect }}>
                        <Typography variant='body2'>{t('weekly')}</Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
                {/* Botón para cambiar de visualización(display) entre gráficas o tabla */}
                {display ?
                    <Tooltip title={`${t('change-view-to-list')}`}>
                        <IconButton onClick={() => changeDisplayMode(0)}>
                            <ListRounded color='secondary' />
                        </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title={`${t('change-view-to-graphic')}`}>
                        <IconButton onClick={() => changeDisplayMode(1)}>
                            <BarChartRounded color='secondary' />
                        </IconButton>
                    </Tooltip>
                }
            </Box>
            {/* Validación de visualización(display) entre gráficas o tabla */}
            {display ?
                <CustomVerticalBarChart data={period === Period.week ? weekCTR : monthCTR} dataKey={"value"} typeDataName={"CTR"} />
                :
                <Box mt={isMobile ? 2 : 0}>
                    {/* Visualizacion de tabla */}
                    <div className="table_analitics">
                        <MaterialTable
                            title=""
                            columns={columns}
                            data={period === Period.week ? weekCTR : monthCTR}
                            options={{
                                exportButton: false,
                                sorting: true,
                                search: false,
                                toolbar: false,
                            }}
                        />
                    </div>
                </Box>
            }
        </Box>
    );
}


export default BestCTR;
