// React
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


// Material UI
import {
    Grid, Container, Box, Button, Typography, Tab, Tabs,
    Dialog, DialogActions, DialogContent, DialogTitle, Divider
} from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos, CloseRounded } from "@material-ui/icons/";

//Custom components
import TabPanel from '../common/admin/TabPanel';
import { CancelButton, SaveButton } from '../common/forms';

// Redux Actions
import { setViewLesson, storeLessonMultimedia, deleteLessonMultimedia } from '../../redux/actions/lessons';
import { courseLessonStep, finishCourse } from '../../redux/actions/courses';
import { IRootState } from '../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import Dropzone, { ILayoutProps } from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'

//Custom Hook
import useScreenWidth from "../../hooks/useScreenWidth";

// Custom Components
import SupportFile from '../common/client/SupportFile';
import CustomSnackBar from '../common/admin/CustomSnackBar';
import TypeFileView from '../common/client/TypeFileView';

// Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles';
import CoursesStyle from '../../styles/components/courses/CoursesStyles';
import VipStyles from '../../styles/components/common/VipStyles';


function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

//Dropzone
const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) => {
    return (
        <div>
            {previews}

            <div {...dropzoneProps}>{files.length < maxFiles && input}</div>

            {files.length > 0 && submitButton}
        </div>
    )
}

// Types
type LessonTemplateProps = {
    lesson: any,
    course: any
}

function LessonsDetail({ lesson, course }: LessonTemplateProps) {
    // Translate
    const { t } = useTranslation(["global"]);

    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const classes = CoursesStyle();
    const formClasses = FormsStyles();
    const vipclasses = VipStyles();
    // Redux Hooks
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { error, msg } = useSelector((state: IRootState) => state.lesson);

    const [lessonMultimediaSelected, setLessonMultimediaSelected] = useState<any>(null);
    const [showEditLessonMultimedia, setShowEditLessonMultimedia] = useState(false);
    //Tab State
    const [currentTab, setCurrentTab] = useState(0);
    //Location
    const location = useLocation();

    let isAdmin = location.pathname.includes('admin')

    // Ready Page
    useEffect(() => {
        window.scrollTo(0, 0);
        if (authUser !== null) {
            dispatch(courseLessonStep({
                course_id: course.id,
                lesson_order: lesson.order
            }));
        }
    }, []);

    /* Functions */
    const handleChangeLesson = (order: number) => {
        course.lessons.map((lessonValue: any) => {
            if (lessonValue.order == order) {
                dispatch(setViewLesson(lessonValue));
            }
        });

        if (authUser !== null) {
            dispatch(courseLessonStep({
                course_id: course.id,
                lesson_order: order
            }));
        }
    };

    const handleFinishLesson = () => {
        dispatch(finishCourse(course.id));
    };

    const handleEditMediaFileBlock = (lessonMultimedia: any) => {
        setLessonMultimediaSelected(lessonMultimedia);
        setShowEditLessonMultimedia(!showEditLessonMultimedia);
    };

    const handleCloseEditLessonMultimedia = () => {
        setLessonMultimediaSelected(null);
        setShowEditLessonMultimedia(!showEditLessonMultimedia);
    };

    const handleDownloadMediaFileBlock = (fileName: string) => {
        window.open(`${process.env.REACT_APP_API_URL}/lessons/download/${fileName}`)
    };

    const handleDeleteMediaFileBlock = (id_lessonmultimedia: number) => {
        let index = lesson.multimedia.findIndex((multimedia: any) => multimedia.id == id_lessonmultimedia);
        lesson.multimedia.splice(index, 1);
        dispatch(setViewLesson(lesson));
        dispatch(deleteLessonMultimedia(id_lessonmultimedia));
    };

    const handleSubmit = () => {
        dispatch(storeLessonMultimedia(lessonMultimediaSelected));
        setLessonMultimediaSelected(null);
        setShowEditLessonMultimedia(!showEditLessonMultimedia);
    }

    //Dropzone
    const getUploadParams = (prop: string) => ({ meta }: any) => {
        setLessonMultimediaSelected({ ...lessonMultimediaSelected, [prop]: meta.name });
        return { url: `${process.env.REACT_APP_API_URL}/lessons/uploaddropzone/${meta.name}` }
    }

    return (
        <>
            {/* <Box mt={isAdmin ? isMobile ? 10 : 7 : 2} mb={isAdmin ? isMobile ? 2 : 6 : 2}>
                <Button variant="text" color="secondary"
                    data-lessondetail="goback"
                    startIcon={<NavigateBeforeRounded />}
                    onClick={() => { dispatch(setViewLesson(null)); }}>
                    {t("back")}
                </Button>
            </Box> */}

            <Container maxWidth="md" style={isMobile ? { padding: 0 } : {}} data-testid="course-lesson-container">
                <Box my={isMobile ? 2 : 0} className={classes.lessonView}>
                    <div dangerouslySetInnerHTML={{ __html: lesson.content }} className={classes.lessonContent}></div>
                </Box>

                <Grid container justify="space-between" alignItems="center" spacing={3} style={{ marginTop: 16 }}>
                    <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="flex-start" gridGap={8}>
                            <Typography variant="body2" color="textSecondary">{t("course")}:</Typography>
                            <Typography variant="body2" color="textSecondary">{course.title}</Typography>
                        </Box>
                        <Box display="flex" alignItems="flex-start" gridGap={8}>
                            <Typography variant="body2" color="textSecondary">Lección N°:</Typography>
                            <Typography variant="body2" color="textSecondary">{lesson.order}</Typography>
                        </Box>
                    </Grid>
                    <Grid item style={{ gap: 8, display: 'flex', justifyContent: 'flex-end' }} xs={12} sm={6}>
                        {(lesson.order > 1) &&
                            <Button
                                variant="outlined"
                                color="primary"
                                className={(course.visibility === 1) ? vipclasses.outline : ''}
                                startIcon={<ArrowBackIos />}
                                onClick={() => handleChangeLesson(lesson.order - 1)}>
                                {t("previous")}</Button>
                        }
                        {(course.lastOrder === lesson.order) ?

                            <Button
                                data-testid="course-lesson-btn-finish"
                                style={{ display: authUser !== null ? 'block' : 'none' }}
                                variant="contained"
                                color="primary"
                                className={(course.visibility === 1) ? vipclasses.button : ''}
                                onClick={() => handleFinishLesson()}>{t("admin.courses.finish-course")}</Button>

                            :

                            <Button
                                data-testid="course-lesson-btn-next"
                                variant="contained"
                                color="primary"
                                className={(course.visibility === 1) ? vipclasses.button : ''}
                                endIcon={<ArrowForwardIos />}
                                onClick={() => handleChangeLesson(lesson.order + 1)}>{t("next")}
                            </Button>

                        }
                    </Grid>
                </Grid>

                {lesson.multimedia.length > 0 &&
                    <Box mt={4} mb={6} gridGap={24} display='grid'>
                        <Divider light />
                        <Typography variant="subtitle1" color="primary">{t("downloadable-material")}</Typography>
                        <Grid container spacing={isMobile ? 2 : 3}>
                            {lesson.multimedia.map((multimedia: any) => (
                                <SupportFile
                                    key={uuidv4()}
                                    data={multimedia}
                                    type="lessons"
                                    vipContent={course.visibility === 1}
                                    hasaccess={course.visibility === 1 && (authUser.vip_lines.includes(course.line_id) || (course.line_id === null && authUser.vip_brands.includes(course.brand_id)) || [1, 2, 3, 5].includes(authUser.role_id))}
                                    handleEdit={handleEditMediaFileBlock}
                                    handleDownload={handleDownloadMediaFileBlock}
                                    handleDelete={handleDeleteMediaFileBlock}
                                />
                            ))}
                        </Grid>
                        <Divider light />
                    </Box>
                }
            </Container>

            {lessonMultimediaSelected !== null &&
                <Dialog
                    open={showEditLessonMultimedia}
                    fullWidth={true}
                    fullScreen={isMobile && true}
                    maxWidth={!isMobile && "md"}
                    className={formClasses.containerForm}
                    onClose={() => handleCloseEditLessonMultimedia()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">

                    <DialogTitle id="alert-dialog-title">
                        <Grid container justify="space-between">
                            <Typography variant="h6">
                                {t("edit")} multimedia {lessonMultimediaSelected.id}
                            </Typography>
                            <Button onClick={() => handleCloseEditLessonMultimedia()}
                                color="primary" variant="text"><CloseRounded /></Button>
                        </Grid>
                    </DialogTitle>

                    <DialogContent className={formClasses.bodyForm}>
                        <form noValidate autoComplete="off">
                            <Grid item xs={12}>
                                <Tabs
                                    value={currentTab}
                                    indicatorColor="secondary"
                                    textColor="primary"
                                    onChange={(event, newValue) => setCurrentTab(newValue)}
                                    className={formClasses.tapForm}
                                    aria-label="disabled tabs example">

                                    <Tab label={t("document")}  {...a11yProps(0)} />
                                    <Tab label={t("cover-image")}  {...a11yProps(1)} />
                                </Tabs>
                            </Grid>

                            <TabPanel value={currentTab} index={0}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>

                                        <Box p={1} display="flex" flexDirection="column" width="100%"
                                            justifyContent="center" my={2} borderRadius={8} gridGap={8}
                                            style={{ backgroundColor: "#FAFAFA" }} >
                                            <Dropzone
                                                getUploadParams={getUploadParams("fileName")}
                                                LayoutComponent={Layout}
                                                classNames={{ dropzone: 'dzu-dropzone-custom', inputLabel: 'dzu-inputlabel-custom' }}
                                                inputContent={t('dropzone-drop-text')}
                                            />

                                            {(lessonMultimediaSelected.fileName === undefined || lessonMultimediaSelected.fileName === null) &&
                                                <>
                                                    <Typography variant="body2" color="textSecondary">{t('admin.library.preview') + ':'}</Typography>
                                                    <TypeFileView
                                                        file={lessonMultimediaSelected}
                                                        type={'lessons'}
                                                    />
                                                </>
                                            }
                                        </Box>

                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={currentTab} index={1}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" color="textSecondary">{t('cover-image') + ':'}</Typography>
                                        <Dropzone
                                            getUploadParams={getUploadParams("fileCover")}
                                            LayoutComponent={Layout}
                                            classNames={{ dropzone: 'dzu-dropzone-custom', inputLabel: 'dzu-inputlabel-custom' }}
                                            inputContent={t('dropzone-drop-text')}
                                            accept="image/*"
                                        />
                                        {lessonMultimediaSelected.cover !== null && lessonMultimediaSelected.fileCover !== undefined &&
                                            <Box p={1} display="grid" justifyContent="center" my={2} borderRadius={8} gridGap={8} style={{ backgroundColor: "#FAFAFA" }} >
                                                <Typography variant="body2" color="textSecondary">{t('admin.library.preview') + ':'}</Typography>
                                                <img src={process.env.REACT_APP_AWS_URL + '/lessons/' + lessonMultimediaSelected.cover}
                                                    width="75%" height="auto"
                                                    alt={lessonMultimediaSelected.cover} />
                                            </Box>
                                        }
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </form>
                    </DialogContent>

                    <DialogActions>
                        <CancelButton onClick={() => setShowEditLessonMultimedia(!showEditLessonMultimedia)}>
                            {t("cancel")}
                        </CancelButton>
                        <SaveButton onClick={() => handleSubmit()} data-lessonmultimedia="saveButton">
                            {t("save")}
                        </SaveButton>
                    </DialogActions>
                </Dialog>
            }

            <CustomSnackBar errors={[error]} msgs={[msg]} />
        </>
    );
}

export default LessonsDetail;
