// React
import { useEffect, useState, ChangeEvent, forwardRef, ReactElement, Ref } from 'react';
import { useDispatch, useSelector } from 'react-redux'

// Material UI
import {
    Button, Dialog, DialogTitle, DialogContent, TextField, Typography, Grid,
    FormHelperText, Slide, DialogActions, Tab, Tabs
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { CloseRounded } from '@material-ui/icons/';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Custom components
import { CancelButton, DatePicker, SaveButton } from '../common/forms';
import TabPanel from '../common/admin/TabPanel';
import { RemoveContent } from '../common/forms/Dropzone';

// Redux Actions
import { createOffer, updateOffer, toggleFormOffer } from '../../redux/actions/offers'
import { getCountries, getFilteredCountries } from '../../redux/actions/countries'
import { getLanguages } from '../../redux/actions/languages'
import { getRoles } from '../../redux/actions/roles'
import { formValidator, resetFormValidator } from '../../redux/actions/validator'
import { IRootState } from '../../redux/types'

// Libraries
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { useTranslation } from "react-i18next";
import moment, { Moment } from 'moment';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

//Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles';

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


// Types
type FileName = {
    type: number
    name: string
}
interface IOffer {
    id: null | number,
    brand: null | any,
    line: null | any,
    country: null | any,
    language: null | any,
    role: any,
    title: string,
    date_ini: Moment | null,
    date_end: Moment | null,
    filesNames: FileName[],
    type: number,
    status: number,
    visibility: number
}

type FormElement = ChangeEvent<HTMLInputElement>;

function OffersModal() {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    // Styles
    const formClasses = FormsStyles();

    /* Global Variables */
    const types = [{ id: 0, name: t('professional') }, { id: 1, name: t('common.client') }];
    const status = [{ id: 0, name: t('active') }, { id: 1, name: t('inactive') }];
    const visibility = [{ id: 0, name: t('private') }, { id: 1, name: 'VIP' }, { id: 2, name: t('public') }];

    //Redux Hooks
    const dispatch = useDispatch();
    const { offer_selected, showForm } = useSelector((state: IRootState) => state.offer);
    const { user: authUser } = useSelector((state: IRootState) => state.auth);
    const { userBrands } = useSelector((state: IRootState) => state.brand);
    const { roles } = useSelector((state: IRootState) => state.role);
    const { countries } = useSelector((state: IRootState) => state.country);
    const { languages } = useSelector((state: IRootState) => state.language);
    const { showModal } = useSelector((state: IRootState) => state.modal);
    const { isValidForm, errorForm, errorTab, msgForm, msgTab } = useSelector((state: IRootState) => state.validator);

    // React Hooks
    const [offerState, setOfferState] = useState<IOffer>({
        id: null,
        brand: null,
        line: null,
        country: null,
        language: null,
        role: [],
        title: '',
        filesNames: [],
        date_ini: null,
        date_end: null,
        type: 0,
        status: 0,
        visibility: 0
    });

    //Tab State
    const [currentTab, setCurrentTab] = useState(0);

    // Ready Page
    useEffect(() => {
        if (roles.length == 0) {
            dispatch(getRoles())
        }
        if (languages.length == 0) {
            dispatch(getLanguages())
        }
    }, []);

    useEffect(() => {
        if (offer_selected != null) {
            setOfferState({
                id: offer_selected.id,
                brand: offer_selected.brand,
                line: offer_selected.line,
                country: offer_selected.country,
                language: offer_selected.language,
                role: offer_selected.role,
                title: offer_selected.title,
                filesNames: [...offer_selected.images.map((file: any) => { return { name: file.file_name, type: file.type } }), ...offer_selected.support_files.map((file: any) => { return { name: file.file_name, type: file.type } })],
                date_ini: (offer_selected.date_ini != null) ? moment(offer_selected.date_ini) : null,
                date_end: (offer_selected.date_end != null) ? moment(offer_selected.date_end) : null,
                type: offer_selected.type,
                status: offer_selected.status,
                visibility: offer_selected.visibility
            });
        }
    }, [offer_selected]);

    useEffect(() => {
        if (authUser != null) {
            if (authUser.role_id == 1)
                dispatch(getCountries())
            else
                dispatch(getFilteredCountries())
        }
    }, [authUser]);

    // Send Form
    useEffect(() => {
        if (isValidForm) {
            let tmpData: any = offerState;
            tmpData.date_ini = (offerState.date_ini != null) ? moment(offerState.date_ini).format('YYYY-MM-DD HH:mm:ss') : null;
            tmpData.date_end = (offerState.date_end != null) ? moment(offerState.date_end).format('YYYY-MM-DD HH:mm:ss') : null;
            dispatch(resetFormValidator());
            if (offerState.id == null) {
                dispatch(createOffer(tmpData));
            } else {
                dispatch(updateOffer(tmpData));
            }
            dispatch(toggleFormOffer());
        }
    }, [isValidForm]);

    /* Functions */
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    //Dropzone
    const getUploadParams = (type: number) => ({ meta }: any) => {
        let tmpFileNames = [...offerState.filesNames];
        tmpFileNames.push({ name: meta.name, type });
        setOfferState({ ...offerState, filesNames: tmpFileNames });
        return { url: `${process.env.REACT_APP_API_URL}/offers/upload/${meta.name}` }
    }

    const handleInputChange = (inputName: string) => (event: FormElement) => {
        const value = event.target.value;
        setOfferState({ ...offerState, [inputName]: value });
    }

    const handleAutoCompleteChange = (inputName: string) => async (event: any, value: any) => {
        setOfferState({ ...offerState, [inputName]: value });
    }

    const handleMultiAutoCompleteChange = (inputName: string) => (event: any, value: any) => {
        let optionsMulti = [];
        if (value !== null) {
            for (let i = 0; i < value.length; i++) {
                optionsMulti.push(value[i].id);
            }
        }
        setOfferState({ ...offerState, [inputName]: optionsMulti });
    }

    const handleNumberChange = (inputName: string) => (event: any, value: any) => {
        if (value != null)
            setOfferState({ ...offerState, [inputName]: value.id });
        else
            setOfferState({ ...offerState, [inputName]: value });
    }

    const handleSubmit = () => {
        if (offerState.id == null) {
            dispatch(formValidator({
                title: [offerState.title, 'required|max:250|min:2'],
                brand: [offerState.brand, 'required'],
                countries: [offerState.country, 'required', 'general'],
                languages: [offerState.language, 'required', 'general'],
                role: [offerState.role, 'notempty', 'general'],
                filesNames: [offerState.filesNames, 'notempty', 'general']
            }));
        } else {
            dispatch(formValidator({
                title: [offerState.title, 'required|max:250|min:2'],
                brand: [offerState.brand, 'required'],
                countries: [offerState.country, 'required', 'general'],
                languages: [offerState.language, 'required', 'general'],
                role: [offerState.role, 'notempty', 'general']
            }));
        }
    }

    const removeFileDropzone = (type: number) => (index: number) => {
        const copyFilesNames = [...offerState.filesNames];
        const filesNamesOfType = copyFilesNames.filter((file) => file.type === type);
        const filesNamesNotType = copyFilesNames.filter((file) => file.type !== type);
        filesNamesOfType.splice(index, 1);
        setOfferState({ ...offerState, filesNames: [...filesNamesOfType, ...filesNamesNotType] });
    }

    return (

        <Dialog open={showForm}
            fullWidth={true}
            fullScreen={isMobile && true}
            maxWidth={!isMobile && "md"}
            className={formClasses.containerForm}
            TransitionComponent={Transition}
            onClose={() => dispatch(toggleFormOffer())}
            aria-describedby="alert-dialog-description"
            aria-labelledby="form-dialog-title"
        >

            <DialogTitle id="form-dialog-title">
                <Grid container justify="space-between">
                    <Typography variant="h6">
                        Crear Oferta
                    </Typography>
                    <Button onClick={() => dispatch(toggleFormOffer())} color="primary" variant="text"><CloseRounded /></Button>
                </Grid>
            </DialogTitle>
            <DialogContent className={formClasses.bodyForm}>
                <Grid container direction="row"
                    alignItems="flex-start" justify="space-between"
                    spacing={2} >

                    <Grid item xs={12} md={(offerState.brand != null) ? 6 : 8}>
                        <TextField
                            label={t("title")}
                            fullWidth
                            required
                            variant="filled"
                            margin="normal"
                            value={offerState.title}
                            error={errorForm.title}
                            helperText={msgForm.title}
                            onChange={handleInputChange('title')}
                        />
                    </Grid>

                    <Grid item xs={12} sm={(offerState.brand != null) ? 6 : 12} md={(offerState.brand != null) ? 3 : 4}>
                        <Autocomplete
                            id="combo-box-brands"
                            value={offerState.brand}
                            onChange={handleAutoCompleteChange('brand')}
                            options={userBrands}
                            getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                            renderInput={(params) => <TextField required {...params} label={t("brand")} error={errorForm.brand} variant="filled" InputLabelProps={{ shrink: true }} />}
                        />
                        <FormHelperText error={errorForm.brand}>{msgForm.brand}</FormHelperText>
                    </Grid>

                    {(offerState.brand != null) &&
                        <Grid item xs={12} sm={6} md={3}>
                            <Autocomplete
                                id="combo-box-lines"
                                value={offerState.line}
                                onChange={handleAutoCompleteChange('line')}
                                options={offerState.brand.active_lines}
                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                renderInput={(params) => <TextField {...params} label={t("lines")} variant="filled" InputLabelProps={{ shrink: true }} />}
                            />
                        </Grid>
                    }

                </Grid>

                <Grid item xs={12}>
                    <Tabs
                        value={currentTab}
                        indicatorColor="secondary"
                        textColor="primary"
                        className={formClasses.tapForm}
                        onChange={handleChangeTab}
                        aria-label="disabled tabs example">

                        <Tab label={t("general")}  {...a11yProps(0)} className={errorTab.general ? formClasses.errorTab : ''} />
                        <Tab label={t("add-ons")}  {...a11yProps(1)} {...a11yProps(1)} className={errorTab.addOn ? formClasses.errorTab : ''} />
                        <Tab label={t("downloadable-material")}  {...a11yProps(2)} className={errorTab.general ? formClasses.errorTab : ''} />

                    </Tabs>
                </Grid>

                <TabPanel value={currentTab} index={0}>
                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                id="combo-box-roles"
                                ChipProps={{ color: 'secondary' }}
                                value={offerState.role}
                                onChange={handleAutoCompleteChange('role')}
                                options={roles.filter((role: any) => role.code > 3)}
                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                renderInput={(params) => <TextField required  {...params}
                                    label={t('roles')} variant="filled" error={errorForm.role} fullWidth InputLabelProps={{ shrink: true }} />}
                            />
                            <FormHelperText error={errorForm.role}>{msgForm.role}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                id="combo-box-country"
                                value={offerState.country}
                                onChange={handleAutoCompleteChange('country')}
                                options={countries}
                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                renderInput={(params) => <TextField required  {...params}
                                    label={t('country')} variant="filled" error={errorForm.countries} fullWidth InputLabelProps={{ shrink: true }} />}
                            />
                            <FormHelperText error={errorForm.countries}>{msgForm.countries}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                id="combo-box-language"
                                value={offerState.language}
                                onChange={handleAutoCompleteChange('language')}
                                options={languages}
                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                renderInput={(params) => <TextField required  {...params}
                                    label={t('language')} variant="filled" error={errorForm.languages} fullWidth InputLabelProps={{ shrink: true }} />}
                            />
                            <FormHelperText error={errorForm.languages}>{msgForm.languages}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                id="combo-box-status"
                                disableClearable
                                value={status.find((stt: any) => stt.id == offerState.status)}
                                onChange={handleNumberChange('status')}
                                options={status}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => <TextField {...params} label={t("status")} variant="filled" InputLabelProps={{ shrink: true }} />}
                            />
                            <FormHelperText error={errorForm.status}>{msgForm.status}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} >
                            <Autocomplete
                                id="combo-box-visibility"
                                disableClearable
                                value={visibility.find((vsb: any) => vsb.id == offerState.visibility)}
                                onChange={handleNumberChange('visibility')}
                                options={visibility}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => <TextField {...params} label={t("visibility")} variant="filled" InputLabelProps={{ shrink: true }} />}
                            />
                            <FormHelperText error={errorForm.visibility}>{msgForm.visibility}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <Autocomplete
                                id="combo-box-types"
                                disableClearable
                                value={types.find((type: any) => type.id == offerState.type)}
                                onChange={handleNumberChange('type')}
                                options={types}
                                getOptionLabel={(option: any) => option.id !== undefined ? option.name : ''}
                                renderInput={(params) => <TextField {...params} label={t("type")} variant="filled" InputLabelProps={{ shrink: true }} />}
                            />
                            <FormHelperText error={errorForm.type}>{msgForm.type}</FormHelperText>
                        </Grid>

                        <Grid item xs={12}>
                            {offerState.id !== null &&
                                <RemoveContent files={offerState.filesNames.filter((file) => file.type === 0).map((file) => `${process.env.REACT_APP_AWS_URL}/offers/${file.name}`)} onHandleClick={removeFileDropzone(0)} />
                            }
                            <Dropzone
                                getUploadParams={getUploadParams(0)}
                                classNames={{ dropzone: 'dzu-dropzone-custom', inputLabel: 'dzu-inputlabel-custom' }}
                                inputContent={`${t("cover-image")}*`}
                                maxFiles={(offerState.id != null) ? 1 : 20}
                            />
                            <FormHelperText error={errorForm.filesNames}>{msgForm.filesNames}</FormHelperText>
                        </Grid>

                    </Grid>
                </TabPanel>

                <TabPanel value={currentTab} index={1}>
                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                ampm={false}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy hh:mm a"
                                label={t("start-date")}
                                InputLabelProps={{ shrink: true, }}
                                fullWidth
                                value={offerState.date_ini}
                                onChange={(newDate: any) => setOfferState({ ...offerState, date_ini: newDate })}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                ampm={false}
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy hh:mm a"
                                label={t("end-date")}
                                InputLabelProps={{ shrink: true, }}
                                fullWidth
                                value={offerState.date_end}
                                onChange={(newDate: any) => setOfferState({ ...offerState, date_end: newDate })}
                            />
                        </Grid>

                    </Grid>
                </TabPanel>
                <TabPanel value={currentTab} index={2}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            {offerState.id !== null &&
                                <RemoveContent files={offerState.filesNames.filter((file) => file.type === 1).map((file) => `${process.env.REACT_APP_AWS_URL}/offers/${file.name}`)} onHandleClick={removeFileDropzone(1)} />
                            }
                            <Dropzone
                                getUploadParams={getUploadParams(1)}
                                classNames={{ dropzone: 'dzu-dropzone-custom', inputLabel: 'dzu-inputlabel-custom' }}
                                inputContent={t('dropzone-drop-text')}
                                maxFiles={10}
                            />
                        </Grid>

                    </Grid>
                </TabPanel>

            </DialogContent>

            <DialogActions className={!isValidForm && Object.keys(msgForm).length > 0 ? formClasses.errorDialogActions : ''}>
                <CancelButton onClick={() => dispatch(toggleFormOffer())}>
                    {t("cancel")}
                </CancelButton>
                <SaveButton onClick={() => handleSubmit()}>
                    {t("save")}
                </SaveButton>
            </DialogActions>
        </Dialog>
    );
}

export default OffersModal;