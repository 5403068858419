// Material
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Typography
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

// Custom components
import { DynamicInputComponent } from "../DynamicInputComponent";

// Libraries
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';

// Types
import { IAddedInputs } from "../../../interfaces/MassAssignmentAddedInputs";

const MassAssigmentBody = ({ data, setData, addedInputs, buttons, addInput, removeInput, handleCheckChange }: any) => {
    const { t } = useTranslation(['global'])

    return (
        <Container maxWidth="lg">
            <Box display="flex" justifyContent="center">
                <Typography variant="h6">
                    {t('what-wish-assign')}
                </Typography>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                flexDirection="row"
            >
                {buttons.map((field: string, index: number) => (
                    <Button
                        key={field}
                        variant="contained"
                        size="medium"
                        color="primary"
                        style={{ margin: 6 }}
                        onClick={() => addInput(field, index)}
                    >
                        {t(field)}
                    </Button>
                ))}
            </Box>
            <div style={{ padding: 20 }}>
                <Grid container spacing={1}>
                    {addedInputs.map(
                        (input: IAddedInputs, index: number) => (
                            <>
                                <Grid key={uuidv4()} item md={9} xs={9}>
                                    <FormControl
                                        fullWidth
                                        variant="filled"
                                    >
                                        <DynamicInputComponent
                                            inputName={input.name}
                                            data={data}
                                            setData={setData}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid
                                    key={uuidv4()}
                                    style={{
                                        display: 'flex',
                                        justifyContent:
                                            'space-evenly',
                                    }}
                                    item
                                    md={3}
                                    xs={3}
                                    alignItems="center"
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    input.checked
                                                }
                                                onChange={
                                                    handleCheckChange
                                                }
                                                name={input.name}
                                                color="primary"
                                            />
                                        }
                                        disabled={
                                            input.overwriteDisabled
                                        }
                                        label="Sobreescribir"
                                    />
                                    <IconButton
                                        aria-label="cancel"
                                        color="primary"
                                        onClick={() =>
                                            removeInput(
                                                input.name,
                                                index
                                            )
                                        }
                                    >
                                        <Cancel />
                                    </IconButton>
                                </Grid>
                            </>
                        )
                    )}
                </Grid>
            </div>
        </Container>
    );
};

export default MassAssigmentBody;