import { useState, ChangeEvent } from 'react';

// Material UI
import { Box, Grid, Tab, Tabs, Typography, Tooltip, IconButton } from "@material-ui/core";
import { BarChartRounded, Info, ListRounded, VisibilityRounded } from '@material-ui/icons';

//Custom Components
import TabPanel from '../common/admin/TabPanel';
import PopularContentChart from './PopularContentChart';

// Libraries
import { useTranslation } from "react-i18next";
import MaterialTable from 'material-table';

// Custom Hooks
import usePopularDetailContent from '../../hooks/graphs/usePopularDetailContent';

// Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles';
import GraphsStyles from '../../styles/components/graphs/GraphsStyles';
import CustomChip from '../common/admin/CustomChip';

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function MostPopularContent() {
    // Translate
    const { t } = useTranslation(["global"]);
    //Styles
    const classes = GraphsStyles();
    const formClasses = FormsStyles();

    // Condicional para el switch de grid a lista
    const [display, setDisplay] = useState<number>(1);
    //Tab state
    const [currentTab, setCurrentTab] = useState(0);

    const { menuCodes, reports } = usePopularDetailContent();

    /* Functions */
    const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    const changeDisplayMode = (mode: number) => {
        setDisplay(mode)
    }

    const columns: any = [
        { title: '#', field: 'foreign_id' },
        {
            title: t("name"), field: "foreign_name", render: (rowData: any) => (
                <Box display="flex" alignItems="center" gridGap={8} my={1} width='100%'>
                    <Box style={{ backgroundImage: `url(${rowData.image})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '32px', width: '32px', borderRadius: '4px' }} />
                    <Box display={'flex'} flexDirection={'column'} className={classes.InfoContent}>
                        <Typography variant='body2'>{rowData.foreign_name}</Typography>
                    </Box>
                </Box>
            )
        },
        { title: t("views"), field: "value" }
    ];

    return (
        <Box width='100%'>
            <Box width={'100%'} display={'flex'} justifyContent='space-between' alignItems={'center'}>
                <Box display={'flex'} alignItems={'center'} gridGap={8} className={classes.heroContent}>
                    <Typography variant='subtitle1'>{t('most-popular-content')}</Typography>
                    <Tooltip placement='bottom' title={
                        <Box maxWidth={'240px'}>
                            <Typography variant='caption'>{t('most-popular-content-quote')}</Typography>
                        </Box>
                    }>
                        <Info fontSize='small' />
                    </Tooltip>
                </Box>
                {/* Botón para cambiar de visualización(display) entre gráficas o tabla */}
                {display ?
                    <Tooltip title={`${t('change-view-to-list')}`}>
                        <IconButton onClick={() => changeDisplayMode(0)}>
                            <ListRounded color='secondary' />
                        </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title={`${t('change-view-to-graphic')}`}>
                        <IconButton onClick={() => changeDisplayMode(1)}>
                            <BarChartRounded color='secondary' />
                        </IconButton>
                    </Tooltip>
                }
            </Box>
            {/* Validación de visualización(display) entre gráficas o tabla */}
            <Grid item xs={12}>
                <Tabs
                    value={currentTab}
                    indicatorColor="secondary"
                    variant='fullWidth'
                    textColor="primary"
                    onChange={handleChangeTab}
                    className={formClasses.tapForm}
                    aria-label="disabled tabs example">

                    {menuCodes.map((menuCode: string, index: number) => (
                        <Tab key={menuCode} label={t(menuCode)} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </Grid>
            {/* Gráfica con top 5 contenidos mas populares entre ofertas y biblioteca*/}
            {menuCodes.map((menuCode: string, index: number) => (
                <TabPanel key={menuCode} value={currentTab} index={index}>
                    {display ? reports[menuCode].length > 0 ?
                        <PopularContentChart data={reports[menuCode]} dataName={"Visualizaciones"} />
                        :
                        <span>Sin registros</span>
                        :
                        <div className="table_analitics">
                            {/* Visualizacion de tabla */}
                            <MaterialTable
                                title=""
                                columns={columns}
                                data={reports[menuCode]}
                                options={{
                                    exportButton: false,
                                    sorting: true,
                                    search: false,
                                    toolbar: false,
                                }}
                            />
                        </div>
                    }
                </TabPanel>
            ))}
        </Box>
    );
}


export default MostPopularContent;
