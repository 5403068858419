// React
import { useState, MouseEvent } from 'react';

// Material UI
import { Typography, makeStyles, Button, Box, Menu, MenuItem, ListSubheader, ListItemIcon } from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

// Libraries
import { useTranslation } from "react-i18next";
import { CheckRounded } from '@material-ui/icons';

//Custom Hook
import useScreenWidth from '../../../hooks/useScreenWidth';

// Custom Style
const FiltersStyle = makeStyles(theme => ({
    containerResp: {
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center"
        },
    },
    startIcon: {
        margin: 0,
    },
    content: {
        color: theme.palette.grey[400],
    },
    contained: {
        borderRadius: 4,
    },
    noLine: {
        borderRight: "none !important",
    },
    buttonActive: {
        color: theme.palette.primary.main,
    },
    popover: {
        maxHeight: 250,
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.grey[200],
            borderRadius: '4px',
            width: '8px',
            margin: "8px",
            '&-thumb': {
                backgroundColor: theme.palette.grey[500],
                borderRadius: '4px'
            },
        },
    },
    menuItem: {
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: 178,
    }
}));

// Types
type AdminContentFilterProps = {
    data: any,
    handleClick: any
}

function AdminContentFilter({ data, handleClick }: AdminContentFilterProps) {
    // Translate
    const { t } = useTranslation(["global"]);
    const [anchorLanOrderBy, setAnchorLanOrderBy] = useState<null | HTMLElement>(null);
    const [options, setOptions] = useState<any>({});
    const [numOptions, setNumOptions] = useState<number>(0);

    //Custom Hook
    const isMobile = useScreenWidth(700);

    // Styles
    const classes = FiltersStyle();

    // Change the options
    const handleClickOption = (input: string) => (event: MouseEvent<HTMLButtonElement>) => {
        let value = parseInt(event.currentTarget.value);
        var tmpOptions: any = { ...options };

        if (options[input] !== undefined) {
            if (options[input] === value) {
                setNumOptions(numOptions - 1);
                delete tmpOptions[input];
            }
            else
                tmpOptions[input] = value
        } else {
            setNumOptions(numOptions + 1);
            tmpOptions[input] = value
        }

        handleClick(tmpOptions);
        setOptions(tmpOptions);
    };

    //New filter functions
    const handleClickLanOrderBy = (event: MouseEvent<HTMLElement>) => {
        setAnchorLanOrderBy(event.currentTarget);
    };

    const handleCloseLanOrderBy = () => {
        setAnchorLanOrderBy(null);
    };

    return (
        <>
            <Box display="flex" alignItems="center">
                <Button
                    aria-controls="filter-by-menu"
                    aria-haspopup="true"
                    style={{ color: "#2E3338" }}
                    onClick={handleClickLanOrderBy}
                    endIcon={<ExpandMoreRoundedIcon color="primary" />}>
                    {isMobile ? t("filter-by") : <Typography variant="body2" style={{ fontWeight: 500 }}>{t("filter-by")} {(numOptions > 0) && `+${numOptions}`}</Typography>}
                </Button>

                <Menu
                    id="filter-by-menu"
                    anchorEl={anchorLanOrderBy}
                    keepMounted
                    open={Boolean(anchorLanOrderBy)}
                    onClose={handleCloseLanOrderBy}
                    onClick={handleCloseLanOrderBy}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    classes={{ paper: classes.popover }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>

                    {data.map((currentData: any) => (
                        <div key={currentData.code}>
                            <ListSubheader disableSticky>{t(`${currentData.code}`)}</ListSubheader>
                            {currentData.data.map((menu: any) => (
                                <MenuItem key={menu.id} value={menu.id} component="button" onClick={handleClickOption(`${currentData.column}`)} classes={{ root: classes.menuItem }}>
                                    {t(menu.name)}
                                    {options[currentData.column] == menu.id &&
                                        <ListItemIcon style={{ minWidth: 32 }}>
                                            <CheckRounded htmlColor='#8DDD55' />
                                        </ListItemIcon>
                                    }
                                </MenuItem>
                            ))}
                        </div>
                    ))}
                </Menu>
            </Box>
        </>
    )
}

export default AdminContentFilter;