// React - Redux
import { useEffect } from 'react';

// Material UI
import { Box, Grid, Tab, Tabs, Typography, Tooltip, IconButton } from "@material-ui/core";
import { BarChartRounded, Info, ListRounded, VisibilityRounded } from '@material-ui/icons';

//Custom Components
import TabPanel from '../common/admin/TabPanel';
import PopularContentChart from './PopularContentChart';

// Custom Hooks
import usePopularContent from '../../hooks/graphs/usePopularContent';
import useNumberState from '../../hooks/useNumberState';

// Libraries
// import { useTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import MaterialTable from 'material-table';

//Custom Hook
import useScreenWidth from '../../hooks/useScreenWidth';

// Styles
import FormsStyles from '../../styles/components/common/forms/FormsStyles';
import GraphsStyles from '../../styles/components/graphs/GraphsStyles';

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function PopularContent() {
    // Translate
    const { t } = useTranslation(["global"]);
    //Custom Hook
    const isMobile = useScreenWidth(700);
    //Styles
    const classes = GraphsStyles();
    const formClasses = FormsStyles();
    //Tab state
    const [currentTab, setCurrentTab] = useNumberState();
    // View Switch State
    const [display, setDisplay] = useNumberState(1);
    const { mostVisualized, mostViewed } = usePopularContent();


    const columns: any = [
        { title: 'ID', field: 'foreign_id' },
        {
            title: t("name"), field: "foreign_name", render: (rowData: any) => (
                <Box display="flex" alignItems="center" gridGap={8} my={1} width='100%'>
                    <Box style={{ backgroundImage: `url(${rowData.image})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '32px', width: '32px', borderRadius: '4px' }} />
                    <Box display={'flex'} flexDirection={'column'} className={classes.InfoContent}>
                        <Typography variant='body2'>{rowData.foreign_name}</Typography>
                    </Box>
                </Box>
            )
        },
        { title: t("menu"), field: "menu", render: (rowData: any) => (<span>{t(rowData.menu)}</span>) },
        { title: currentTab === 1 ? t("reproductions") : t("views"), field: 'value' },
    ];

    return (
        <Box width='100%'>
            <Box width={'100%'} display={'flex'} justifyContent='space-between' alignItems={'center'}>
                <Box display={'flex'} alignItems={'center'} gridGap={8} className={classes.heroContent}>
                    <Typography variant='subtitle1'>{t('most-popular-videos')}</Typography>
                    <Tooltip placement='bottom' title={
                        <Box maxWidth={'240px'}>
                            <Typography variant='caption'>{t('most-popular-videos-quote')}</Typography>
                        </Box>
                    }>
                        <Info fontSize='small' />
                    </Tooltip>
                </Box>
                {/* Botón para cambiar de visualización(display) entre gráficas o tabla */}
                {display ?
                    <Tooltip title={`${t('change-view-to-list')}`}>
                        <IconButton onClick={() => setDisplay(0)}>
                            <ListRounded color='secondary' />
                        </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title={`${t('change-view-to-graphic')}`}>
                        <IconButton onClick={() => setDisplay(1)}>
                            <BarChartRounded color='secondary' />
                        </IconButton>
                    </Tooltip>
                }
            </Box>

            <Grid item xs={12}>
                <Tabs
                    value={currentTab}
                    indicatorColor="secondary"
                    textColor="primary"
                    variant={isMobile ? "scrollable" : "fullWidth"}
                    scrollButtons={isMobile ? "on" : "off"}
                    onChange={(event, newValue) => setCurrentTab(newValue)}
                    className={formClasses.tapForm}
                    aria-label="disabled tabs example">

                    <Tab label={t('visualizations-number')} {...a11yProps(0)} />
                    <Tab label={t('minutes-played')} {...a11yProps(1)} />
                </Tabs>
            </Grid>
            {/* Gráfica con mas numero de visualizaciones*/}
            <TabPanel value={currentTab} index={0}>
                {display ?
                    <PopularContentChart data={mostVisualized} dataName={"Visualizaciones"} />
                    :
                    <Box mt={isMobile ? 2 : 0}>
                        {/* Visualizacion de tabla */}
                        <div className="table_analitics">
                            <MaterialTable
                                title="Visualizaciones"
                                columns={columns}
                                data={mostVisualized}
                                options={{
                                    exportButton: false,
                                    sorting: true,
                                    search: false,
                                    toolbar: false,
                                }}
                            />
                        </div>
                    </Box>
                }
            </TabPanel>
            {/* Gráfica con mas minutos reproducidos */}
            <TabPanel value={currentTab} index={1}>
                {display ?
                    <PopularContentChart data={mostViewed} dataName={'Min. Visualizados'} />
                    :
                    <Box mt={isMobile ? 2 : 0}>
                        {/* Visualizacion de tabla */}
                        <div className="table_analitics">
                            <MaterialTable
                                title=""
                                columns={columns}
                                data={mostViewed}
                                options={{
                                    exportButton: false,
                                    sorting: true,
                                    search: false,
                                    toolbar: false,
                                }}
                            />
                        </div>
                    </Box>
                }
            </TabPanel>
        </Box>
    );
}


export default PopularContent;
