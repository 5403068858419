// React
import { useState, MouseEvent } from "react";
import { useSelector } from 'react-redux';

// Material UI
import { Typography, Grid, Box, IconButton, Menu, MenuItem, Button, Card, CardContent } from '@material-ui/core';
import { Description, Videocam, MoreHoriz } from '@material-ui/icons';

//Custom components
import FiletypeIcon from '../../../components/library/FiletypeIcon';
import TooltipCards from "../typography/TooltipCards";
import HiddenDesktop from "../HiddenDesktop";

// Redux Actions
import { IRootState } from '../../../redux/types';

// Libraries
import { useTranslation } from "react-i18next";

//Custom Hook
import useScreenWidth from "../../../hooks/useScreenWidth";

// Styles
import VipStyles from '../../../styles/components/common/VipStyles';
import BlockStyles from '../../../styles/components/library/ResourceStyle';

type SupportFileProps = {
  data: any,
  type: "lessons" | "seminarlessons" | "offers",
  vipContent: boolean,
  hasaccess: boolean,
  handleEdit?: (value: any) => void,
  handleDownload?: (value: string) => void,
  handleDelete?: (value: number) => void
}

function SupportFile({ data, type, vipContent, hasaccess = false, handleEdit, handleDownload, handleDelete }: SupportFileProps) {

  // Translate
  const { t } = useTranslation(["global"]);

  //Custom Hook
  const isMobile = useScreenWidth(700);

  // Styles
  const classes = BlockStyles();
  const vipclasses = VipStyles();

  //Redux Hooks
  const [anchorOp, setAnchorOp] = useState<null | HTMLElement>(null);
  const [openOptions, setOpenOptions] = useState(false);
  const { user: authUser } = useSelector((state: IRootState) => state.auth);

  /* Functions */
  const handleCloseOptions = () => {
    setAnchorOp(null);
    setOpenOptions(false);
  };

  const TypeFile = () => {
    const fileNameExtension = data.ext === null ? '' : data.ext.toUpperCase();

    const $imageExtensions = ['JPG', 'GIF', 'PNG', 'JPEG', 'BMP', 'TIF'];
    const $fileExtensions = ['PDF', 'DOCX', 'XLS'];
    const $videoExtensions = ['MP4', 'MOV', 'WMV', 'AVI'];

    if ($imageExtensions.includes(fileNameExtension) || data.cover !== null) {
      const tmpFilename = (data.cover === null || data.cover === undefined) ? data.file_name : data.cover;
      return <Box className={classes.media}
        style={{
          background: `${encodeURI(`url(${process.env.REACT_APP_AWS_URL}/${type}/${tmpFilename})`)}`
        }}
        title={data.file_name} >
        {(vipContent) &&
          <Box textAlign="center" className={vipclasses.label}>
            <Typography variant={isMobile ? 'body1' : "subtitle1"}>VIP</Typography>
          </Box>
        }
      </Box>
    } else if ($videoExtensions.includes(fileNameExtension)) {
      return <Box className={classes.media}>
        <Videocam fontSize="large" color="inherit" />
        {(vipContent) &&
          <Box textAlign="center" className={vipclasses.label}>
            <Typography variant={isMobile ? 'body1' : "subtitle1"}>VIP</Typography>
          </Box>
        }
      </Box>
    } else if ($fileExtensions.includes(fileNameExtension)) {
      return <Box className={classes.media}>
        <Description fontSize="large" color="inherit" />
        {(vipContent) &&
          <Box textAlign="center" className={vipclasses.label}>
            <Typography variant={isMobile ? 'body1' : "subtitle1"}>VIP</Typography>
          </Box>
        }
      </Box>
    } else {
      return <Box className={classes.media}>
        <Description fontSize="large" color="inherit" />
        {(vipContent) &&
          <Box textAlign="center" className={vipclasses.label}>
            <Typography variant={isMobile ? 'body1' : "subtitle1"}>VIP</Typography>
          </Box>
        }
      </Box>
    }
  }

  return (
    <>
      <Grid item xs={6} sm={4} md={useScreenWidth(1000) ? 6 : 4} lg={3} className='websiteResource'>

        <Card className={classes.card + ' ' + (vipContent && vipclasses.shadow)} >
          <TypeFile />

          <CardContent classes={{ root: classes.resourceText1 }}>
            <Box
              className={classes.title}>
              <HiddenDesktop>
                <FiletypeIcon id={data.filetype_id} />
              </HiddenDesktop>
              <TooltipCards titleCard={data.alias && data.alias !== null ? data.alias : data.file_name} classNameCustom='resourceTitle' />
            </Box>

            <Box display="flex" justifyContent="end" >
              <IconButton
                id={`icon-button-${data.id}`}
                className={classes.optionsMobile}
                aria-controls="icon-menu"
                aria-haspopup="false"
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  setAnchorOp(event.currentTarget);
                  setOpenOptions(true);
                }}>

                <MoreHoriz />
              </IconButton>
            </Box>
          </CardContent>
        </Card>
        {/* Hidden content vip */}
        {vipContent && !hasaccess &&
          <Box alignContent="center" textAlign="center" className={vipclasses.block}>
            <Typography variant="subtitle1" color="inherit">{t('this-is-a-content')}</Typography>
            <Typography variant="h5" color="inherit">VIP</Typography>
          </Box>
        }
      </Grid>

      <Menu
        id={`simple-menu-${data.id}`}
        anchorEl={anchorOp}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={openOptions}
        onClose={handleCloseOptions}
        onClick={handleCloseOptions}
      >
        {(handleEdit !== undefined) && ([1, 2, 5].includes(authUser.role_id)) &&
          <MenuItem onClick={() => handleEdit(data)}>{t("edit")}</MenuItem>
        }
        {handleDownload &&
          <MenuItem onClick={() => handleDownload(data.file_name)}>{t("download")}</MenuItem>
        }

        {(type === "lessons" || type === "seminarlessons") && authUser !== null && ([1, 2, 5].includes(authUser.role_id)) && handleDelete &&
          <MenuItem onClick={() => handleDelete(data.id)}>{t("delete")}</MenuItem>
        }
      </Menu>

    </>
  );
}

export default SupportFile;
